.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
} /*css format for the pagination part*/

.App-link {
  color: #61dafb;
}

.EBS-filter-row {
  display: inline-block;
  align-items: center;
  margin-bottom: 1rem;
}

.EBS-select-filter,
.EBS-input-filter {
  display: inline-block;
  margin-right: 1rem;
}

.EBS-storage-amount-filter,
.EBS-amount-changed-per-snapshot-filter {
  display: flex;
  align-items: center;
}

.EBS-storage-amount-filter > *,
.EBS-amount-changed-per-snapshot-filter > * {
  margin-right: 0.5rem;
}

.ebs-attached-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.9rem;
}

.EBS-storage-amount-filter .notice-message {
  max-width: 500px; /* Adjust the maximum width as needed */
  word-wrap: break-word; /* Enable line breaks for long words */
}

.ebs-attached-header > *:first-child {
  margin-right: 0.5rem; /* Adjust the spacing between the label and icon */
}

.input-container {
  display: flex;
  width: 800px; /* Adjust this value as needed */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe#webpack-dev-server-client-overlay{display:none!important}