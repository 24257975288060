.main-title.left-align {
  text-align: left;
}

.sub-title {
  text-align: left;
}

.paragraph {
  text-align: left;
  margin: 0;
  /* padding: 0; */
  /* list-style-type: disc; */
  /* padding-left: 20px; */
}

.section {
  margin-top: 20px;
}